import CallToAction2 from "../components/CallToAction2";
import TextBlock from "../components/TextBlock";
import Prices from "../components/Prices";
import Mentor from "../components/Mentor";
import Venue from "../components/Venue";
import Footer from "../components/Footer";
import HeaderCourse from "../components/HeaderCourse";
import ImageGallery from "../components/ImageGallery";
import { PhoneIcon, SupportIcon, SaveAsIcon } from "@heroicons/react/outline";

const description = (
  <div>
    <p class="my-2">
      This is a <b>NO TECHNICAL</b> course
    </p>
    <p class="my-2">
    Need to generate an image in seconds or generate 
    a detailed text with ChatGPT?
    </p>
  </div>
);

const subtitle = (
  <p>
    A journey into Generative AI to understanad and master its potential.
    <br />
    This course is designed for <b>non-technical</b> people (no coding skills required)
  </p>
);

// const supportLinks = [
//   {
//     name: "Max 20 students",
//     description: (
//       <p>
//         Anyone can attend this course, but to fully enjoy the hands-on sessions
//         it is advisable a basic Python knowldge. But anyone curious about ML and
//         DL are can join even if he/she can't code.
//       </p>
//     ),
//     icon: PhoneIcon,
//   },
//   {
//     name: "Schedule and organization",
//     description: (
//       <div>
//         <p>
//           We will have lessons only in the morning from 9:30, with some breaks
//           in between.
//         </p>
//         <p className="py-3">
//           For the ones willing to learn to surf, we will go in the afternoon,
//           ready to catch some waves! 🌊🏄‍♂️
//         </p>
//       </div>
//     ),
//     icon: SupportIcon,
//     link: (
//       <div>
//         <p className="py-2">First day of lesson: 1/3/2023 - (Wednesday)</p>
//         <p className="py-2">Last day of lesson: 4/03/2023 - (Saturday)</p>
//       </div>
//     ),
//   },
//   {
//     name: "Where",
//     href: "https://www.google.com/maps/place/Surfing+Colors/@28.7419265,-13.8711471,16.82z/data=!4m8!3m7!1s0x0:0xe8e4eed62badc2!5m2!4m1!1i2!8m2!3d28.7422639!4d-13.8700805",
//     description: (
//       <p>
//         We will be in Fuerteventura - Canary Islands (Spain). The coworking
//         space is inside{" "}
//         <a
//           className="text-blue-500 underline"
//           rel="noreferrer"
//           href="https://www.surfingcolors.com/"
//           target="_blank"
//         >
//           Surfing Colors Hotel{" "}
//         </a>
//         and the arrival airport is <span className="text-indigo-600">FUE</span>
//       </p>
//     ),
//     icon: PhoneIcon,
//     link: "Google Maps",
//   },
// ];

const info_where_when = [
	{
		name: "Admission",
		href: "",
		description: `The course is for anyone willing to understand Generative AI 
    intuitively and in practical terms. This is not a theoretical course even if you will understand why Generative AI works. No previous knowledge required.`,
		icon: SaveAsIcon,
		link: null,
	},
	{
		name: "When & Deadline",
		href: "mailto:info@codingwaves.org",
		description: <div><p><b>Course dates:</b> 8th-10th October 2023 (first and last day of lesson), 4 hrs per day. </p>
		<p><b>Deadline for pre-registration:</b> 8 September 2023</p></div>,
		icon: SupportIcon,
		link: "Contact us (info[at]codingwaves.org)",
	},
	{
		name: "Where",
		href: "https://www.google.com/maps/place/Surfing+Colors/@28.7422639,-13.8700805,15z/data=!4m8!3m7!1s0x0:0xe8e4eed62badc2!5m2!4m1!1i2!8m2!3d28.7422639!4d-13.8700805",
		description:
			"We will be in the sunny Fuerteventura (Canary Island - Spain) in the Coworking space of Surfing Colors Hotel.",
		icon: PhoneIcon,
		link: "Google Maps (Surfing Colors)",
		link2: "Surfing Colors Hotel (website)",
	},
];

const description_course = (
  <div>
    <p className="text-justify mb-5">
      <span className="text-xl m-2 bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
        This is a <b>NO TECHNICAL</b> course (no coding)
      </span>
    </p>
    <p className='my-2'>
      Generative AI is more and more pervasive and omnipresent in our life and society.
      It is rapidly changing many traditional job fields like copy writing, photo retouching,
      advertisements, animations, web site design and even coding.
    </p>
    <p className='my-2'>Did you know that some fashion-brands are <b>generating</b> pictures of models with
      brand clothes? Impressive.</p>
    <p className='my-2'>
      We think that this is only the <b>beginning</b> of the trend, because a few years from now
      this technology will even improve and get better. 
    </p>
    <p className='my-2'>
      So, curious about how to fully leverage the potential of ChatGPT to write an article?
      Need to generate an ultra-realistic image with MidJourney in seconds?
    </p>
    <p className='my-2'>
      We'll cover the basics of Generative AI technologies, how they works and you will learn
      the main cutting-edge tools to master it.
    </p>
    <p>
      Let be guided by our mentors to understand this new world.
    </p>
  </div>
);

const topics = [
  {
    title:
      "What is Generative AI and where it fits inside the AI panorama.",
  },
  {
    title: "How Generative AI works? An intuition, some examples and applications",
  },
  {
    title: "How to use the most cutting-edges technologies.",
  },
  {
    title: "How to use ChatGPT (generation text) and what it can do.",
  },
  {
    title: "How to use MidJourney (generative images) and what it can do.",
  },
  {
    title: "How to unlock the powewr of GPT4 and its plugins.",
  }
];

const info_prices = {
	price: 450,
	what_to_bring: ["Laptop and charger", "Pen and papers for notes"],
	included: ["Lessons: 16 hours of lessons with skilled trainer", "Coworking space"],
	not_included: ["Flight", "Accomodation (but you will have a special discount code)", 
  "Commuting from/to airport", "Surf Lessons (but you will have a special discount"],
};

export default function PromptEngineering() {
  return (
    <>
      {/* <Banner /> */}
      <HeaderCourse
        title="Generative AI and Prompt Engineering"
        subtitle={subtitle}
        bg_image="bg-aiexec"
      />
      <CallToAction2
        subtitle="CodingWaves"
        title="Unleash the potential of Generative AI with the most cutting-edge tools."
        description={description}
      />
      <TextBlock
        short_uptitle={"Understand and control Generative AI"}
        title={"Generative AI and Prompt Engineering"}
        description={description_course}
        topics={topics}
      />
      <Mentor mentor_names={["Cristiano De Nobili", "Carlo Ceriotti"]} />
      <Venue info_where_when={info_where_when}/>
      <ImageGallery />
      <Prices {...info_prices} />
      <Footer />
    </>
  );
}
