import CallToAction2 from "../components/CallToAction2";
import TextBlock from "../components/TextBlock";
import Prices from "../components/Prices";
import Mentor from "../components/Mentor";
import Venue from "../components/Venue";
import Footer from "../components/Footer";
import HeaderCourse from "../components/HeaderCourse";
import ImageGallery from "../components/ImageGallery";
import { PhoneIcon, SupportIcon, SaveAsIcon } from "@heroicons/react/outline";

const description = (
	<div>
		<p class="my-2">
			This is a <b>TECHNICAL</b> course (Python coding 🐍)
		</p>
		<p class="my-2">
			Unlock your potential in the world of data science with our immersive,
			week-long bootcamp designed specifically for Python-savvy working
			professionals.
		</p>
		<br />
		<p>
			<b>
				No prior experience in data science, machine learning, or AI is
				required.
			</b>
		</p>
		<br />
		<p>
			All you need is a solid understanding of Python programming and a
			high-school level knowledge of mathematics to embark on this exciting
			journey.
			<br />
			<b>Bring your laptop and join us!</b>
		</p>
	</div>
);

const subtitle = <p>Learn the principles of Data Science with Python</p>;

const description_course = (
	<div>
		<p className="text-justify mb-5">
			<span className="text-xl m-2 bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
				This is a <b>TECHNICAL</b> course (Python coding 🐍)
			</span>
		</p>
		<p>Looking to get a grip on Deep Learning and how to create models?</p>
		<p>
			We will explore Deep Learning and why it is different from Machine
			Learning.
		</p>
		<p>
			At the end of the course, you will have gained a solid grasp on how a NN
			is working, is trained, how to make inferences and how to correctly tune a
			NN choosing its structure and complexity for your data.
		</p>
		<p>
			The course is <b>hands-on</b>: we will use Python as coding language with{" "}
			<b>PyTorch</b> as deep learning library.
		</p>
	</div>
);

const topics = [
	{
		title: "Day 1: Arrival & Networking",
		text: "Settle into your accommodations and get to know your fellow participants during a lively networking session.",
	},
	{
		title: "Day 2: Mastering Data Science Basics with Python",
		text: 'Learn to work with tabular data using "numpy" and "pandas" libraries. Acquire skills merging datasets from various sources',
	},
	{
		title: "Day 3: Data Acquisition & Cleaning Techniques",
		text: "Gain expertise in web scraping with Beautiful Soup and Selenium. Utilize APIs for data collection and perform common data-cleaning tasks.",
	},
	{
		title: "Day 4: Exploratory Data Analysis (EDA) Essentials",
		text: "Delve into simple statistical analysis techniques. Unleash the power of data visualization with Matplotlib and Seaborn.",
	},
	{
		title: "Day 5: Image & Text Data Processing",
		text: "Discover image processing with OpenCV. Unlock the secrets of text processing using NLTK.",
	},
	{
		title: "Day 6: Machine Learning Fundamentals",
		text: "Get acquainted with popular machine learning algorithms using Scikit-learn. Master linear and logistic regression, and explore other machine learning models.",
	},
	{
		title: "Day 7: Group Project & Hackathon",
		text: "Collaborate on an engaging group project, putting your newly acquired skills to the tes. Showcase your work during the final presentation.",
	},
	{
		title: "Day 8: Farewell & Departure",
		text: "Depart with newfound knowledge, skills, and connections in the data science community.",
	},
];

const info_where_when = [
	{
		name: "Admission",
		href: "",
		description: `The course is for anyone willing to understand how AI is impacting our 
    society and how to organise and design properly an AI project.`,
		icon: SaveAsIcon,
		link: null,
	},
	{
		name: "When",
		href: "mailto:info@codingwaves.org",
		description:
			"19th-26th September 2023 (first and last day of lesson), 4 hrs per day.",
		icon: SupportIcon,
		link: "Contact us (info[at]codingwaves.org)",
	},
	{
		name: "Where",
		href: "https://www.google.com/maps/place/Surfing+Colors/@28.7422639,-13.8700805,15z/data=!4m8!3m7!1s0x0:0xe8e4eed62badc2!5m2!4m1!1i2!8m2!3d28.7422639!4d-13.8700805",
		description:
			"We will be in the sunny Fuerteventura (Canary Island - Spain) in the Coworking space of Surfing Colors Hotel.",
		icon: PhoneIcon,
		link: "Google Maps (Surfing Colors)",
		link2: "Surfing Colors Hotel (website)",
	},
];

const info_prices = {
	price: 450,
	what_to_bring: ["Laptop and charger", "Pen and papers for notes"],
	included: [
		"Lessons: 16 hours of lessons with skilled trainer",
		"Coworking space",
	],
	not_included: [
		"Flight",
		"Accomodation (but you will have a special discount code)",
		"Commuting from/to airport",
		"Surf Lessons (but you will have a special discount",
	],
};

export default function PythonDataScience() {
	return (
		<>
			{/* <Banner /> */}
			<HeaderCourse
				title="Python Data Science"
				subtitle={subtitle}
				bg_image="bg-deeplearning"
			/>
			<CallToAction2
				subtitle="CodingWaves"
				title="Data Science with Python"
				description={description}
			/>
			<TextBlock
				short_uptitle={"Learn Data Science with Python"}
				title={"Theory and practice"}
				description={description_course}
				topics={topics}
			/>
			<Mentor mentor_names={["Vikram Mark, Ph.D.", "Carlo Ceriotti"]} />
			<Venue info_where_when={info_where_when} />
			<ImageGallery />
			<Prices {...info_prices} />
			<Footer />
		</>
	);
}
