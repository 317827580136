import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Homepage from "./Pages/Homepage";
import DeepLearningPage from "./Pages/DeepLearningPage";
import AiForExecutivesFuerte from "./Pages/AiForExecutives_fuerte";
import AiForExecutivesMilan from "./Pages/AiForExecutives_milan";
import DataScienceForProfessionals from "./Pages/DataScienceForProfessionals";
import DataExploration from "./Pages/DataExploration";
import PromptEngineering from "./Pages/PromptEngineering";
import GenerativeAIWithPython from "./Pages/GenerativeAIPython";
import DataVizInformationDesign from "./Pages/DataVizInformationDesign";
import Scraping from "./Pages/Scraping";
import NLP from "./Pages/NLP";
import PythonDataScience from "./Pages/PythonDataScience";

// import PythonPage from "./Pages/PythonPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const Router = () => (
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<Homepage />} />
			<Route path="/deeplearning" element={<DeepLearningPage />} />
			<Route
				path="/ai_for_executives_fuerte"
				element={<AiForExecutivesFuerte />}
			/>
			<Route
				path="/ai_for_executives_milan"
				element={<AiForExecutivesMilan />}
			/>
			<Route
				path="/data_viz_information_design"
				element={<DataVizInformationDesign />}
			/>
			<Route path="/data_exploration" element={<DataExploration />} />
			<Route path="/prompt_engineering" element={<PromptEngineering />} />
			<Route path="/scraping" element={<Scraping />} />
			<Route path="/nlp" element={<NLP />} />
			<Route
				path="/data-science-for-professionals"
				element={<DataScienceForProfessionals />}
			/>
			<Route
				path="/generative_ai_python"
				element={<GenerativeAIWithPython />}
			/>
			<Route path="/python_data_science" element={<PythonDataScience />} />
		</Routes>
	</BrowserRouter>
);

// ReactDOM.createRoot(<Router />, document.getElementById("root"));
ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<Router />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
