import Header from "../components/Header";
import CallToAction from "../components/CallToAction";
import CallToAction2 from "../components/CallToAction2";
import Footer from "../components/Footer";
import Events from "../components/Events";
// import Locations from "../components/Locations";
// import BasicSection from "../components/BasicSection";
// import ComposeExperience from "../components/ComposeExperience";
import { useState } from "react";
import Team from "../components/Team";
// import NumberNavigation from "../components/NumberedMenu";

export default function Homepage() {
	const [selection, setSelection] = useState({ locations: [], courses: [] });

	const handleSelectionCourse = (course, event) => {
		const status = event.target.checked;
		if (status) {
			setSelection({
				...selection,
				courses: [...selection.courses, course],
			});
		} else {
			if (selection.courses.indexOf(course) !== -1) {
				setSelection({
					...selection,
					courses: selection.courses.filter((loc) => loc !== course),
				});
			}
		}
	};

	return (
		<>
			{/* <Banner /> */}
			<Header />
			<CallToAction subtitle="" description="" title="" />
			<CallToAction2 subtitle="" description="" title="" />

			{/* <BasicSection subtitle="" description="" title="" /> */}
			<Events handleSelectionCourse={handleSelectionCourse} />
			<Team />
			{/* <Locations handleSelectionLocation={handleSelectionLocation} /> */}
			{/* <ComposeExperience
        subtitle=""
        title=""
        chosen_locations={selection.locations}
        chosen_courses={selection.courses}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        email={email}
        handleChosenPeriod={handleChosenPeriod}
        statusCode={statusCode}
      /> */}
			{/* <ComposeExperienceBottom
        subtitle="Choose your experience"
        title="You are interested in..."
        chosen_locations={selection.locations}
        chosen_courses={selection.courses}
      /> */}
			<Footer />
		</>
	);
}
