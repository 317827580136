import { CheckCircleIcon } from "@heroicons/react/solid";

function Package({ info_package_object }) {
	const { title, what_to_bring, included, not_included, price } =
		info_package_object;
	return (
		<div className="mt-8 bg-white pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
			<div className="relative">
				<div className="absolute inset-0 h-1/2 bg-gray-100" />
				<div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					<div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
						<div className="flex-1 bg-white px-6 py-8 lg:p-12">
							<h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
								{title}
							</h3>
							<div className="mt-8">
								<div className="flex items-center">
									<h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">
										What to bring
									</h4>
									<div className="flex-1 border-t-2 border-gray-200" />
								</div>
								<ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
									{what_to_bring.map((feature) => (
										<li
											key={feature}
											className="flex items-start lg:col-span-1"
										>
											<div className="flex-shrink-0">
												<CheckCircleIcon
													className="h-5 w-5 text-green-400"
													aria-hidden="true"
												/>
											</div>
											<p className="ml-3 text-sm text-gray-700">{feature}</p>
										</li>
									))}
								</ul>
							</div>
							<div className="mt-8">
								<div className="flex items-center">
									<h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">
										What's included
									</h4>
									<div className="flex-1 border-t-2 border-gray-200" />
								</div>
								<ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
									{included.map((feature) => (
										<li
											key={feature}
											className="flex items-start lg:col-span-1"
										>
											<div className="flex-shrink-0">
												<CheckCircleIcon
													className="h-5 w-5 text-green-400"
													aria-hidden="true"
												/>
											</div>
											<p className="ml-3 text-sm text-gray-700">{feature}</p>
										</li>
									))}
								</ul>
							</div>
							<div className="mt-8">
								<div className="flex items-center">
									<h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-indigo-600">
										What's NOT included (but we help you to organise if you
										wish)
									</h4>
									<div className="flex-1 border-t-2 border-gray-200" />
								</div>
								<ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
									{not_included.map((feature) => (
										<li
											key={feature}
											className="flex items-start lg:col-span-1"
										>
											<div className="flex-shrink-0">
												<CheckCircleIcon
													className="h-5 w-5 text-red-400"
													aria-hidden="true"
												/>
											</div>
											<p className="ml-3 text-sm text-gray-700">{feature}</p>
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
							<p className="text-lg leading-6 font-medium text-gray-900">
								Price
							</p>
							<div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
								<span>{price}</span>
								<span className="ml-3 text-xl font-medium text-gray-500">
									EUR
								</span>
							</div>
							{/* <p className="mt-4 text-sm">
                  <a href="#" className="font-medium text-gray-500 underline">
                    Learn about our membership policy
                  </a>
                </p> */}
							<div className="mt-6">
								<div className="rounded-md shadow">
									<a
										href="https://mailchi.mp/0f69a4e40e3e/codingwaves"
										// href="https://drive.google.com/drive/folders/1Ika2Hc6XGd3LJM2_XoeZLY-cL78YHBHV?usp=sharing"
										target="_blank"
										rel="noreferrer"
										className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-lime-600 hover:bg-lime-400"
									>
										Get detailed course syllabus
									</a>
								</div>
							</div>
							<div className="mt-6">
								<div className="rounded-md shadow">
									<a
										href="https://codingwaves.typeform.com/to/uswrbFr4"
										className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-800 hover:bg-blue-500"
									>
										Show your interest(pre-registration)
									</a>
								</div>
							</div>
							{/* <div className="mt-6">
									<div className="rounded-md shadow">
										<a
											target="_blank"
                      rel="noreferrer"
											href={syllabus}
											className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-blue-500"
										>
											Download detailed program
										</a>
									</div>
								</div> */}
							{/* <div className="mt-4 text-sm">
                  <a href="#" className="font-medium text-gray-900">
                    Confirm{" "}
                    <span className="font-normal text-gray-500">(20MB)</span>
                  </a>
                </div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Package;
