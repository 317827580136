import CallToAction2 from "../components/CallToAction2";
import TextBlock from "../components/TextBlock";
import Prices from "../components/Prices";
import Mentor from "../components/Mentor";
import Venue from "../components/Venue";
import Footer from "../components/Footer";
import HeaderCourse from "../components/HeaderCourse";
import ImageGallery from "../components/ImageGallery";
import { PhoneIcon, SupportIcon, SaveAsIcon } from "@heroicons/react/outline";
// import syllabus from "../syllabus/NLP-program-CodeNet.pdf";

import fuerte1 from "../images/coliving2.jpg";
import fuerte2 from "../images/coliving3.jpg";
import fuerte3 from "../images/coliving4.jpg";

import coworking_milan1 from "../images/coworking_milan1.jpeg";
import coworking_milan2 from "../images/coworking_milan2.jpeg";
import coworking_milan3 from "../images/coworking_milan3.jpeg";

const description = (
	<div>
		<p class="my-2">
			Subscribe to CodingWaves mailing list and get free materials about AI. Be
			updated about new courses.
		</p>
	</div>
);

const subtitle = (
	<p>
		{/* Looking to get a grip on AI and how to use it to drive your business
		forward?
		<br />
		This course is for CEO and Executives */}
	</p>
);

const description_course = (
	<div>
		<p>
			<b>Unlock your potential</b> in the world of data science with our
			immersive, week-long bootcamp designed specifically for{" "}
			<b>Python-savvy working professionals</b>.
		</p>
		<p>
			No prior experience in data science, machine learning, or AI is required.{" "}
			<b>All you need is a solid understanding of Python programming</b> and a
			high-school level knowledge of mathematics to embark on this exciting
			journey.
		</p>
	</div>
);

const topics = [
	{
		title: "Data Web Acquisition and Web Scraping",
		text: "Gain expertise in web scraping with Beautiful Soup and Selenium. Utilize APIs for data collection and perform common data-cleaning tasks.",
	},
	{
		title: "Data Manipulation and Cleaning Techniques",
		text: "Learn to work with tabular data using 'Numpy' and 'Pandas' libraries. Acquire skills in merging datasets from various sources.",
	},
	{
		title: "Exploratory Data Analysis (EDA) Essentials",
		text: "Delve into simple statistical analysis techniques. Unleash the power of data visualization with Matplotlib and Seaborn.",
	},
	{
		title: "Machine Learning Fundamentals",
		text: "Get acquainted with popular machine learning algorithms using Scikit-learn. Master linear and logistic regression, and explore other machine learning models.",
	},
	{
		title: "Group Project & Hackathon",
		text: "Collaborate on an engaging group project, putting your newly acquired skills to the test. Showcase your work during the final presentation.",
	},
	{
		title: "Farewell & Departure",
		text: "Depart with newfound knowledge, skills, and connections in the data science community.",
	},
];

const info_where_when = [
	{
		name: "You have...",
		href: "https://mailchi.mp/0f69a4e40e3e/codingwaves",
		description: (
			<p>
				No prior experience in data science, machine learning, or AI is
				required.
				<b>All you need is a solid understanding of Python programming</b> and a
				high-school level knowledge of mathematics to embark on this exciting
				journey.
			</p>
		),
		icon: SaveAsIcon,
		link: "Download the course syllabus",
	},
	{
		name: "Date, duration, deadline",
		href: "mailto:info@codingwaves.org",
		description: (
			<div>
				<p>
					<b>Course dates:</b> 27 October 2024 (first day of lesson) to 2th
					November 2024 (last lesson day)
				</p>
				<p>
					<b>Deadline for pre-registration:</b> 15 September 2024
				</p>
			</div>
		),
		icon: SupportIcon,
		link: "Contact us (info[at]codingwaves.org)",
	},
	{
		name: "Location",
		href: "https://www.google.com/maps/place/Surfing+Colors/@28.7422639,-13.8700805,15z/data=!4m8!3m7!1s0x0:0xe8e4eed62badc2!5m2!4m1!1i2!8m2!3d28.7422639!4d-13.8700805",
		description:
			"We will be in the sunny Fuerteventura (Canary Island - Spain) in the Coworking space of Surfing Colors Hotel.",
		icon: PhoneIcon,
		link: "Google Maps (Surfing Colors)",
		link2: "Surfing Colors Hotel (website)",
	},
];

const info_prices = [
	{
		title: "Data Science Course only",
		price: 605,
		what_to_bring: [
			"Laptop and charger",
			"Pen and papers for notes",
			"Swimsuit",
			"Sunscreen",
		],
		included: ["Data Science Course with skilled trainer", "Coworking space"],
		not_included: [
			"Breakfast, Lunch and Dinner",
			"Flight",
			"Commuting from/to airport",
			"Surf lessons (3 days)",
			"Accomodation",
		],
	},
	{
		title: "Course + Surf",
		price: 755,
		what_to_bring: [
			"Laptop and charger",
			"Pen and papers for notes",
			"Swimsuit",
			"Sunscreen",
		],
		included: [
			"Data Science Course with skilled trainer",
			"Coworking space",
			"Surf Lessons (3 afternoon) - full equipment provided",
		],
		not_included: [
			"Flight",
			"Lunch and Dinner",
			"Airport Commuting",
			"Accomodation",
		],
	},
	{
		title: "Course + Surf + Double Room",
		price: 1070,
		what_to_bring: [
			"Laptop and charger",
			"Pen and papers for notes",
			"Swimsuit",
			"Sunscreen",
		],
		included: [
			"Data Science Course with skilled trainer",
			"Coworking space",
			"Surf Lessons (3 afternoon) - full equipment provided",
			"Accomodation in DOUBLE room with private bathroom",
			"Buffet Breakfast",
		],
		not_included: ["Flight", "Lunch and Dinner", "Airport Commuting"],
	},
	{
		title: "Course + Surf + Single Room",
		price: 1245,
		what_to_bring: [
			"Laptop and charger",
			"Pen and papers for notes",
			"Swimsuit",
			"Sunscreen",
		],
		included: [
			"Data Science Course with skilled trainer",
			"Coworking space",
			"Accomodation in SINGLE room with private bathroom",
			"Surf Lessons (3 afternoon) - full equipment provided",
			"Buffet Breakfast",
		],
		not_included: ["Flight", "Lunch and Dinner", "Airport Commuting"],
	},
];

const images_coworking = {
	fuerteventura: [fuerte1, fuerte2, fuerte3],
	milan_this_is_combo: [coworking_milan1, coworking_milan2, coworking_milan3],
};

export default function DataScienceForProfessionals() {
	let location_name = "fuerteventura";
	const queryParameters = new URLSearchParams(window.location.search);
	if (queryParameters.size === 1) {
		location_name = queryParameters.get("location_name");
	} else {
		location_name = "fuerteventura";
	}

	return (
		<>
			{/* <Banner /> */}
			<HeaderCourse
				title="Python Data Science Bootcamp for Professionals"
				subtitle={subtitle}
				bg_image="bg-dspro"
			/>
			<CallToAction2
				subtitle="CodingWaves"
				title="Get free materials about AI"
				description={description}
			/>
			<TextBlock
				short_uptitle={"Unleash and control AI power"}
				title={"Data Science for professionals"}
				description={description_course}
				topics={topics}
			/>
			<Mentor mentor_names={["Vikram Mark", "Carlo Ceriotti"]} />
			<Venue info_where_when={info_where_when} />
			<ImageGallery images_coworking={images_coworking[location_name]} />
			<Prices info_prices_list={info_prices} />
			<Footer />
		</>
	);
}
