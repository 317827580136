export default function CallToAction({ subtitle, title, description }) {
	if (subtitle === "") {
		subtitle = "FREE MUST-HAVE AI RESOURCES";
	}
	if (title === "") {
		title = "GET MUST-HAVE AI RESOURCES FOR FREE";
	}
	if (description === "") {
		description =
			"A collection of carefully selected and MUST-HAVE links about AI: blogs, tutorials and much more about AI fundamentals, from linear-algebra to advance topics. We selected these resources for you, by hand.";
	}

	return (
		<div className="py-12 bg-hero-pattern bg-cover text-white bg-fixed">
			<div
				className="max-w-4xl rounded-3xl overflow-hidden mx-auto 
      h-full md:p-12 sm:pb-10 xs:p-3 md:bg-black text-center
      md:bg-opacity-80"
			>
				<h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
					{subtitle}
				</h2>
				<h2 className="text-3xl font-extrabold sm:text-4xl">{title}</h2>
				<div className="text-xl text-justify">
					<div className="px-5 py-5">
						<p>{description}</p>
					</div>
				</div>
				<a
					href="https://mailchi.mp/0f69a4e40e3e/codingwaves"
					// href="https://drive.google.com/drive/folders/1Ika2Hc6XGd3LJM2_XoeZLY-cL78YHBHV"
					target="_blank"
					rel="noreferrer"
				>
					<button
						type="button"
						className="rounded-md bg-indigo-600 px-8 py-5 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 
          text-lg
          focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					>
						GET COURSES SYLLABUS + FREE AI RESOURCES
					</button>
				</a>
			</div>
		</div>
	);
}
