import fuerte1 from "../images/coliving2.jpg";
import fuerte2 from "../images/coliving3.jpg";
import fuerte3 from "../images/coliving4.jpg";

export default function ImageGallery({images_coworking = []}) {
  if (images_coworking.length === 0){
    images_coworking = [fuerte1, fuerte2, fuerte3];
  }
  return (
    <div className="bg-black">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-white">
              Coworking Space
            </h2>
            <p className="text-xl text-gray-300">
              Example of Coworking space.
            </p>
          </div>
          <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
            <li>
              <div className="space-y-4">
                <div className="aspect-w-3 aspect-h-2">
                  <img
                    className="object-cover shadow-lg rounded-lg"
                    src={images_coworking[0]}
                    alt=""
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="space-y-4">
                <div className="aspect-w-3 aspect-h-2">
                  <img
                    className="object-cover shadow-lg rounded-lg"
                    src={images_coworking[1]}
                    alt=""
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="space-y-4">
                <div className="aspect-w-3 aspect-h-2">
                  <img
                    className="object-cover shadow-lg rounded-lg"
                    src={images_coworking[2]}
                    alt=""
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
