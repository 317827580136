/* This example requires Tailwind CSS v2.0+ */
import Package from "./Package";

// import syllabus from "../syllabus/NLP-program-CodeNet.pdf";

// const includedFeatures = [
// 	"Theory and Practice - NLP with Pytorch",
// 	"Expert Trainer specialised in NLP",
// 	"NLP lessons: 25 hours",
// ];

// const notIncludedFeatures = [
// 	"Flight and transportation",
// 	"Accomodation",
// 	"Meals",
// ];

export default function Prices({ info_prices_list }) {
	return (
		<div className="bg-gray-100">
			<div className="pt-12 sm:pt-16 lg:pt-20">
				<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
					<div className="text-center">
						<h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl lg:text-5xl mb-5">
							Pre-registration
						</h2>
						<h3 className="text-xl text-gray-900 sm:text-xl lg:text-xl">
							(no payment now - just show your interest)
						</h3>

						<h3 className="text-xl text-gray-900 sm:text-xl lg:text-xl m-auto lg:w-1/2 w-full">
							<span class="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800 mt-5">
								IMPORTANT NOTICE:
								<br /> in case we receive less than 10 pre-registration before
								the deadline date, the event will be automatically cancelled. If
								not, we will contact you for the organisation
							</span>
						</h3>

						{/* <p className="mt-4 text-xl text-gray-600">
              Reservation deadline: 10 October 2022 - 23:59PM Rome Time
            </p> */}
					</div>
				</div>
			</div>
			{info_prices_list.map((single_package) => (
				<Package info_package_object={single_package} />
			))}
		</div>
	);
}
