import CallToAction2 from "../components/CallToAction2";
import TextBlock from "../components/TextBlock";
import Prices from "../components/Prices";
import Mentor from "../components/Mentor";
import Venue from "../components/Venue";
import Footer from "../components/Footer";
import HeaderCourse from "../components/HeaderCourse";
import ImageGallery from "../components/ImageGallery";

const description = (
  <div>
    <p class="my-2">
      This is a <b>NO TECHNICAL</b> course
    </p>
    <p class="my-2">
      How to visually represent data, avoid common mistakes and communicate data
      and information at a glance.
    </p>
    <p>This course is tool-agnostic: just bring pencils and paper </p>
  </div>
);

const subtitle = (
  <p>
    Have you ever felt the urge to understand insights in a visual way? This
    course let you know how.
  </p>
);

const description_course = (
  <div>
    <p className="text-justify mb-5">
      <span className="text-xl m-2 bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
        This is a <b>NO TECHNICAL</b> course (no coding)
      </span>
    </p>
    <p>
      Data visualization is the graphical representation of information and
      data. In order to properly do that it is important to understand the
      information design principles.{" "}
    </p>
    <p>
      The importance of understanding information design principles in data
      visualization can not be understated.{" "}
    </p>
    The advent of tools for visualizing data and the increasing business
    intelligence dashboards have underlined the importance of designers in the
    data production chain.{" "}
    <p>
      This is because the ability to effectively communicate data and insights
      through visual means is crucial both in decision making and understanding
      complex information.
    </p>
  </div>
);

const topics = [
  {
    title:
      "Data storytelling: What the history of data visualization can teach us",
  },
  {
    title: "Common mistakes to avoid when you show data",
  },
  {
    title: "Best practices in representing data visually",
  },
  {
    title: "Common mistakes in data visualization",
  },
  {
    title: "How to represent more than three variables",
  },
];

export default function AiForExecutives() {
  return (
    <>
      {/* <Banner /> */}
      <HeaderCourse
        title="Data Viz and Information Design"
        subtitle={subtitle}
        bg_image="bg-information_design_bonera"
      />
      <CallToAction2
        subtitle="CodeNet"
        title="Data visualization and information design"
        description={description}
      />
      <TextBlock
        short_uptitle={"Discover data information design"}
        title={"Data viz - theory and practice"}
        description={description_course}
        topics={topics}
      />
      <Mentor mentor_names={["Matteo Bonera"]} />
      <Venue />
      <ImageGallery />
      <Prices />
      <Footer />
    </>
  );
}
