/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import header_img1 from "../images/header1.jpg";
import header_img2 from "../images/header2.jpg";
import header_img4 from "../images/header4.jpg";
import logo from "../images/logo.png";
import { Link } from "react-scroll";

const navigation = [
  // { name: "What it is", href: "#", section_name: "section1" },
  // { name: "Team and Mentors", href: "#", section_name: "section2" },
  // { name: "Venue", href: "#", section_name: "section1" },
  // { name: "Price", href: "#", section_name: "section1" },
];

const header_images = [header_img1, header_img2, header_img4];

function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const chosen_image = header_images[randomInteger(0, header_images.length - 1)];

export default function HeaderCourse({ title, subtitle, bg_image }) {
  return (
    <div className={`relative ${bg_image} bg-cover h-screen`}>
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
          <Popover>
            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav
                className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                aria-label="Global"
              >
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <a href="www.google.com">
                      <span className="sr-only">Workflow</span>
                      {/* <img
                        alt="logo"
                        className="h-10 w-auto sm:h-10"
                        src={logo}
                      /> */}
                    </a>
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open main menu</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                  {navigation.map((item) => (
                    <Link
                      href={item.href}
                      key={item.name}
                      to={item.section_name}
                      offset={-50}
                      duration={500}
                      className="font-medium text-gray-500 hover:text-gray-900"
                    >
                      {item.name}
                    </Link>
                  ))}
                  <a
                    href="/reservation"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    {/* Log in */}
                  </a>
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      {/* <img
                        className="h-8 w-auto"
                        src="../images/logo.png"
                        alt=""
                      /> */}
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close main menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3 space-y-1">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to="section1"
                        offset={-100}
                        duration={500}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  {/* <a
                    href="#"
                    className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100"
                  >
                    Log in
                  </a> */}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <main className="mt-8 mx-auto max-w-5xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28 bg-white bg-opacity-50 p-4 rounded-xl">
            <div className="text-center">
              <div className="content-center">
                <a href="#" className="flex items-center justify-center">
                  <img alt="logo" className="w-1/3 mb-5 md:w-1/6" src={logo} />
                </a>
              </div>
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline" id="text-header">
                  {title} <br />
                </span>{" "}
                {/* <div className="block text-green-400">Canary Islands</div> */}
              </h1>
              <div className="mt-5 sm:mt-8 mb-8 flex justify-center">
                {/* <div className="rounded-md shadow">
                  <a
                    href="#"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-lime-600 hover:bg-lime-700 md:py-4 md:text-lg md:px-10"
                  >
                    Reserve your place
                  </a>
                </div> */}
                <p className="text-black text-xl font-semibold">{subtitle}</p>
              </div>
            </div>
          </main>
        </div>
      </div>
      {/* <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <motion.div
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
          initial={{ opacity: 0 }}
        >
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            src={chosen_image}
            alt=""
          />
        </motion.div>
      </div> */}
    </div>
  );
}
