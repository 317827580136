import CallToAction2 from "../components/CallToAction2";
import TextBlock from "../components/TextBlock";
import Prices from "../components/Prices";
import Mentor from "../components/Mentor";
import Venue from "../components/Venue";
import Footer from "../components/Footer";
import HeaderCourse from "../components/HeaderCourse";
import ImageGallery from "../components/ImageGallery";

const description = (
  <div>
    <p class="my-2">
      This is a <b>TECHNICAL</b> course (Python coding 🐍)
    </p>
    <p class="my-2">
      Do you need to get <b>massive amount of data</b> from web pages in an{" "}
      <b>automated</b>,<b>fast</b> and <b>reliable</b> to train your Deep
      Learning model?
    </p>
    <p>Bring your laptop and join us!</p>
  </div>
);

const subtitle = (
  <p>
    Learn how to build bots to get massive amount of data from web in an
    automated, realiable and fast way!
  </p>
);

const description_course = (
  <div>
    <p className="text-justify mb-5">
      <span className="text-xl m-2 bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
        This is a <b>TECHNICAL</b> course (Python coding 🐍)
      </span>
    </p>
    <p>
      They ask you to train a model, but to get the data from many web pages?
      How? With web scraping!
    </p>
    <p>
      We will cover the fundamentals of web scraping, building a bot/crawler
      able to control the browser programmatically and get data.
    </p>
    <p>
      With web scraping technologies, you can build bots able to get{" "}
      <b>massive</b> amount of data in an automated, reliable and fast way.
    </p>
    <p>
      The course is in Python and there will be theory and hands-on projects: we
      will use Python and at the end you will have build a powerful and fast web
      scraping bot able to get large amount of data.
    </p>
  </div>
);

const topics = [
  {
    title: "What is web scraping and what is a bot/spider",
  },
  {
    title: "Basics of web pages (nodes, html, classes...)",
  },
  {
    title: "Learn XPATH to target your nodes",
  },
  {
    title: "Build your first bot for static web pages",
  },
  {
    title: "Build your first bot for dynamic web pages",
  },
  {
    title: "How to put in production your bot",
  },
];

export default function AiForExecutives() {
  return (
    <>
      {/* <Banner /> */}
      <HeaderCourse
        title="Web scraping"
        subtitle={subtitle}
        bg_image="bg-scraping"
      />
      <CallToAction2
        subtitle="CodeNet"
        title="Web Scraping"
        description={description}
      />
      <TextBlock
        short_uptitle={"Get massive data at speed-light!"}
        title={"Web scraping"}
        description={description_course}
        topics={topics}
      />
      <Mentor mentor_names={["Carlo Ceriotti", "Cristiano De Nobili"]} />
      {/* <Venue /> */}
      {/* <ImageGallery /> */}
      {/* <Prices /> */}
      <Footer />
    </>
  );
}
