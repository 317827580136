import Cristiano from "../images/pic-blackboard.jpg";
import Carlo from "../images/carlo_foto_profilo.jpg";
import Matteo from "../images/matteo_bonera_profile_pic.jpg";
import Vikram from "../images/Vikram_pic.jpeg";
import cv from "../images/cv.png";
import cv_pdf_cristiano from "../images/Cristiano-De-Nobili_CV_Aug-22.pdf";
import cv_matteo_bonera from "../images/Matteo_Bonera_Resume_202212_nodati.pdf";
import cv_vikram from "../images/CV_Codingwaves_Vikram.pdf"

const team = [
  {
    name: "Cristiano De Nobili",
    role: "Physicist ∣↑↓⟩ | Lead AI Scientist | Lecturer & Speaker. Deep Learning Scientist and Mentor",
    image: Cristiano,
    cv: cv_pdf_cristiano,
    linkedinUrl: "https://www.linkedin.com/in/cristiano-de-nobili/",
    description: (
      <p className="text-white">
        Currently, Cristiano is a Lead AI Scientist at Pi School tackling social
        or environmental challenges by leveraging emerging technologies. He
        holds a{" "}
        <span className="text-indigo-400">Ph.D. in Theoretical Physics</span>{" "}
        from the International School for Advanced Studies (SISSA, Trieste). He
        pursued research in Quantum Field and Quantum Information Theory, in
        particular on entanglement entropy. Afterwards, he has been involved in{" "}
        <span className="text-indigo-400">
          Deep Learning R&D for five years.
        </span>
        Starting from computer vision, he then switched to NLP and for three
        years he was part, as a Research Scientist, of the AI team working on
        Samsung's virtual assistant. Moreover, Cristiano is a Machine Learning
        lecturer at the Master in High-Performance Computing (SISSA/ICTP,
        Trieste), at Bicocca University. In the private sector, he has been
        instructing Deep Learning for five years all around Europe. More in
        general, he has a strong interest in AI and Quantum Technologies, in
        particular for environmental applications. Besides technical topics,
        Cristiano is a VC advisor, an inspirational speaker (TEDxer), and a
        civil pilot.
        <a
          className="text-blue-500 underline"
          href="https://twitter.com/denocris"
        >
          Twitter
        </a>{" "}
        and{" "}
        <a
          className="text-blue-500 underline"
          href="https://www.linkedin.com/in/carlo-ceriotti-63ba707a/"
        >
          LinkedIn
        </a>
      </p>
    ),
  },
  {
    name: "Carlo Ceriotti",
    role: "Biomedical Engineer, Data Scientist, Data Engineer",
    linkedin: "https://www.linkedin.com/in/carlo-ceriotti-63ba707a/",
    image: Carlo,
    linkedinUrl: "https://www.linkedin.com/in/carlo-ceriotti-63ba707a/",
    description: (
      <p className="text-white">
        Carlo is currently working as Data Scientist for a large online travel
        agency. He holds a Master Degree in Biomedical Engineering from
        Politecnico di Milano and an executive master in Big Data Analytics from
        University of Pisa.
        <br />
        In his work he deals with Dynamic Pricing problems using Reinforcement
        Learning techniques. His primary coding language is Python (more than 6
        years of experience) and he enjoys web scraping, machine learning and
        deep learning. Among his skillset there is also Javascript, React and
        web developement in general. He likes writing clear and easily scalable
        softwares because he thinks that a well written code is similar to a
        piece of art made by an experienced craftman.{" "}
        <a
          className="text-blue-500 underline"
          href="https://www.linkedin.com/in/carlo-ceriotti-63ba707a/"
        >
          LinkedIn
        </a>
      </p>
    ),
  },
  {
    name: "Matteo Bonera",
    role: "Professor at Politecnico di Milano, Creative Director of The Visual Agency and Knowledge visualization expert for European Commission",
    cv: cv_matteo_bonera,
    linkedin: "https://www.linkedin.com/in/matteobonera/",
    image: Matteo,
    linkedinUrl: "https://www.linkedin.com/in/matteobonera/",
    description: (
      <div>
        <p className="text-white">
          With more than 12 years of professional experience working on national
          and international projects, he has extensive expertise in the fields
          of communication and information design, ranging from data and
          knowledge visualization to motion graphics and the design of
          cutting-edge digital experiences. His multidisciplinary background in
          visual, multimedia, and information design enables him to push the
          creative boundaries of complex communication projects in the public
          and private sectors. <br />
          Many projects created under his leadership have won international
          prizes and awards. Since 2015 he has held the position of contract
          professor at the Politecnico di Milano and visiting lecturer at other
          universities. He taught information and communication design to more
          than 1,000 international students and professionals in training
          courses and master's programs.
          <a
            className="text-blue-500 underline"
            href="https://www.linkedin.com/in/matteobonera/"
          >
            LinkedIn
          </a>
        </p>
      </div>
    ),
  },
  {
    name: "Vikram Mark",
    role: "Lecturer in applied data science and A.I. | GCP Professional Machine Learning Engineer | Databricks certified associate developer | Nvidia certified DLI instructor",
    cv: cv_vikram,
    linkedin: "https://www.linkedin.com/in/vikram-mark-radhakrishnan-90038660/",
    image: Vikram,
    linkedinUrl: "https://www.linkedin.com/in/vikram-mark-radhakrishnan-90038660/",
    description: (
      <div>
        <p className="text-white">
        Teaching and developing course material in data science, data engineering, deep learning, and machine learning operations in Hague University of Applied Sciences. Guiding students with project work. Previously, researching in non-linear control of Adaptive Optics systems for the direct imaging of exoplanets. Utilizing deep neural networks and reinforcement learning in AO control systems for high contrast imaging.
          <a
            className="text-blue-500 underline"
            href="https://www.linkedin.com/in/vikram-mark-radhakrishnan-90038660/"
          >
            LinkedIn
          </a>
        </p>
      </div>
    ),
  },
];

export default function Team({ mentor_names }) {
  const filtered_mentors = team.filter((m) => mentor_names.includes(m.name));
  return (
    <div id="section2">
      <div className="bg-gray-900">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12">
            <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
              <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                Team and Mentors
              </h2>
            </div>
            <ul
              className="space-y-4 sm:grid sm:grid-cols-1 sm:gap-6 sm:space-y-0 lg:grid-cols-2 lg:gap-2"
            >
              {filtered_mentors.map((person) => (
                <li
                  key={person.name}
                  className="py-10 px-6 bg-gray-800 text-center rounded-lg xl:px-10 xl:text-left"
                >
                  <div className="space-y-6 xl:space-y-10">
                    <img
                      className="mx-auto h-40 w-40 rounded-full object-cover xl:w-56 xl:h-56"
                      src={person.image}
                      alt=""
                    />
                    <div className="space-y-2 xl:flex xl:items-center xl:justify-between">
                      <div className="font-medium text-lg leading-6 space-y-1">
                        <h3 className="text-white">{person.name}</h3>
                        <p className="text-indigo-400">{person.role}</p>
                      </div>

                      <ul
                        className="flex justify-center space-x-5 p-5"
                      >
                        <li>
                          <a
                            href={person.linkedinUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-400 hover:text-gray-300"
                          >
                            <span className="sr-only">Linkedin url link</span>
                            <svg
                              className="w-10 h-10"
                              aria-hidden="true"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </a>
                        </li>

                        {person.cv && (
                          <li className="w-10">
                            <a
                              href={person.cv}
                              target="_blank"
                              rel='noreferrer'
                              className="text-blue-400 hover:text-gray-300"
                            >
                              <span className="sr-only">
                                Curriculum {person.name}
                              </span>
                              <img src={cv} />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div>{person.description}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
