export default function CallToAction({ subtitle, title, description }) {
  if (subtitle === "") {
    subtitle = "What is CodingWaves?";
  }
  if (title === "") {
    title = (
      <p className="p-3">
        AI bootcamps for <span className="text-orange-400">adventurous</span> and <span className="text-green-400">active</span> people{" "}
      </p>
    );
  }
  if (description === "") {
    description = (
      <p className="mx-4">
        We are AI professionals willing to share our knowledge by organising 
        short bootcamps on AI topics. 
        <br /><br /> After lessons it is sport time! Surfing, biking, hiking and many more 
        (depending on the location).<br /><br />
        {/* <span class="inline-flex items-center rounded-md bg-blue-400/20 p-4 font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30">
        What about...Tech course in Tenerife
        with surf lessons in the sunset ?</span> */}
      </p>
    );
  }


  // md:bg-hero-pattern
  return (
    <div className="md:mx-0 py-12 bg-black md:bg-cover text-white bg-fixed">
      <div
        className="max-w-5xl rounded-3xl overflow-hidden mx-auto 
      text-center h-full md:p-12 sm:pb-10 xs:p-3 md:bg-black 
      md:bg-opacity-80"
      >
        <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
          {subtitle}
        </h2>
        <h2 className="text-3xl font-extrabold sm:text-4xl">{title}</h2>
        <div className="text-xl mt-4">{description}</div>
      </div>
    </div>
  );
}
