import CallToAction2 from "../components/CallToAction2";
import TextBlock from "../components/TextBlock";
import Prices from "../components/Prices";
import Mentor from "../components/Mentor";
import Venue from "../components/Venue";
import Footer from "../components/Footer";
import HeaderCourse from "../components/HeaderCourse";
import ImageGallery from "../components/ImageGallery";

const description = (
  <div>
    <p class="my-2">
      This is a <b>TECHNICAL</b> course (Python coding 🐍)
    </p>
    <p class="my-2">
      You have many noisy and dirty data: how to clean them before feeding them
      into your model?
      <br />
      <b>Bring your laptop and join us!</b>
    </p>
  </div>
);

const subtitle = <p>Learn how to clean, explore and manipulate data</p>;

const description_course = (
  <div>
    <p className="text-justify mb-5">
      <span className="text-xl m-2 bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
        This is a <b>TECHNICAL</b> course (Python coding 🐍)
      </span>
    </p>
    <p>In Machine Learning it is common to say "Garbage in, garbage out".</p>
    <p>
      With "dirty" data your model won't perform well. A large part of a data
      scientist effort is deputed to "data cleaning".
    </p>
    <p>
      Which values do your data have? Do you see outliers in data which can ruin
      the performances of your machine learning model? Do you see non-sense
      values, duplication and outliers?
    </p>
    <p>
      In this course you will get a grasp on why cleaning data is important, how
      to clean them and get basic statistical information out of them.
    </p>
    <p>
      The course is <b>hands-on</b>: we will use Python as coding language with
      package <b>Pandas</b> for data manipulation, <b>Numpy</b> for basic
      statistical analysis, <b>Matplotlib</b> and <b>Seaborn</b> for visualising
      them.
    </p>
  </div>
);

const topics = [
  {
    title:
      "Garbage in, garbage out: why data cleaning is important in a Machine Learning pipeline",
  },
  {
    title: "Get basic statistical information from your data",
  },
  {
    title: "One hot encoding",
  },
  {
    title: "Visualise data with Matplotlib and Seaborn",
  },
  {
    title: "Manipulate your data with Pandas",
  },
];

export default function MachineLearning() {
  return (
    <>
      {/* <Banner /> */}
      <HeaderCourse
        title="Data Exploration, clearning and manipulation"
        subtitle={subtitle}
        bg_image="bg-dataexploration"
      />
      <CallToAction2
        subtitle="CodeNet"
        title="Data Exploration"
        description={description}
      />
      <TextBlock
        short_uptitle={"Discover data exploration"}
        title={"Data exploration - theory and practice"}
        description={description_course}
        topics={topics}
      />
      <Mentor mentor_names={["Carlo Ceriotti", "Cristiano De Nobili"]} />
      {/* <Venue /> */}
      {/* <ImageGallery /> */}
      {/* <Prices /> */}
      <Footer />
    </>
  );
}
