import header_img1 from "../images/header1.jpg";
import header_img2 from "../images/header2.jpg";
import header_img4 from "../images/header4.jpg";
// import ML_img from "../images/ML_img5.jpg";
import dspro from "../images/surf_on_beach1.jpg";
// import magic_ball from "../images/datascience_img.jpg";
// import NLP_img from "../images/NLP_img.jpg";
// import deep_learning from "../images/deep_learning_img.jpg";
// import scraping_img from "../images/scraping_img.jpg";
// import data_exploration from "../images/data_exploration_img.jpg";
// import data_design from "../images/data_design_img2.jpg";
// import { NumberedMenuWhite } from "../components/NumberedMenu";

const header_images = [header_img1, header_img2, header_img4];

function randomInteger(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

function choose_random_img() {
	return header_images[randomInteger(0, header_images.length - 1)];
}

const events = [
	{
		name: "Python Data Science Bootcamp for Professionals",
		link: "data-science-for-professionals",
		image: dspro,
		type: ["Coding", "Machine Learning", "Python"],
		event_date: ["27 October 2024 - 2 November 2024"],
		location: ["Fuerteventura Island - Spain"],
		mentors: ["Vikram Mark", "Carlo Ceriotti"],
		deadline: ["CANCELED: not enough subscribers (6/14)"],
		description: (
			<div>
				<p className="mb-2">
					<b>Unlock</b> your potential in the world of data science with our
					immersive,
					<b>week-long bootcamp</b> designed specifically for Python-savvy
					working professionals.
					<b>
						No prior experience in data science, machine learning, or AI is
						required.
					</b>{" "}
					All you need is a solid understanding of Python programming and a
					high-school level knowledge of mathematics to embark on this exciting
					journey.
				</p>
			</div>
		),
	},
	// {
	// 	name: "AI for executives(Canary 🇪🇸)",
	// 	link: "ai_for_executives_fuerte",
	// 	image: ai_executive_img,
	// 	type: ["No coding", "For tech managers and CTO"],
	// 	location: ["Fuerteventura - October 13th - 15th, 2023"],
	// 	deadline: ['Reservation deadline: 13 September 2023'],
	// 	description: (
	// 		<div>
	// 			<p className="mb-2">
	// 				This is a <b>no-coding</b> overview about AI. The winning recipe for
	// 				CEO, managers and no-tech people willing to know how to use AI to
	// 				solve <b>real world business problems</b> and how to face a full
	// 				end-to-end AI project.
	// 			</p>
	// 		</div>
	// 	),
	// },
	// {
	// 	name: "AI for executives (Milan 🇮🇹)",
	// 	link: "ai_for_executives_milan",
	// 	image: magic_ball,
	// 	type: ["No coding", "For tech managers and CTO"],
	// 	location: ["Milan - October 14th, 2023 (saturday) "],
	// 	deadline: ['Reservation deadline: 14 September 2023'],
	// 	description: (
	// 		<div>
	// 			<p className="mb-2">
	// 				This is a <b>no-coding</b> overview about AI. The winning recipe for
	// 				CEO, managers and no-tech people willing to know how to use AI to
	// 				solve <b>real world business problems</b> and how to face a full
	// 				end-to-end AI project.
	// 			</p>
	// 		</div>
	// 	),
	// },
	// {
	// 	name: "Generative AI and Prompt Engineering",
	// 	link: "prompt_engineering",
	// 	image: scraping_img,
	// 	type: ["No Code", "Generative Technologies", "ChatGPT Prompt"],
	// 	location: ["Fuerteventura 🇪🇸 - October 8th - 10th, 2023"],
	// 	// event_date: ["October 5th - 8th, 2023"],
	// 	deadline: ['Reservation deadline: 8 September 2023'],
	// 	description: (
	// 		<div>
	// 			<p className="mb-2">
	// 				Understand the power of Generative AI, how it works and how to use the
	// 				tools to generate text and stunning images. This is a not technical course
	// 				and we won't write code.
	// 			</p>
	// 		</div>
	// 	),
	// },
	// {
	// 	name: "Python Data Science",
	// 	link: "python_data_science",
	// 	image: NLP_img,
	// 	type: ["Python Coding", "Data Science", "Tech"],
	// 	location: ["Fuerteventura 🇪🇸 - August 19th - 26th, 2023"],
	// 	// event_date: ["August 19th - 26th, 2023"],
	// 	deadline: ['Reservation deadline: 1 August 2023'],
	// 	description: (
	// 		<div>
	// 			<p>
	// 				Unlock your potential in the world of data science with our immersive,
	// 				week-long bootcamp designed specifically for Python-savvy working
	// 				professionals.
	// 			</p>
	// 		</div>
	// 	),
	// },
	// {
	//   name: "Information Design",
	//   link: "data_viz_information_design",
	//   image: data_design,
	//   type: ["No coding", "Design"],
	//   description: (
	//     <div>
	//       <p className="mb-2">
	//         Have you ever felt the urge to understand <b>insights</b> by
	//         presenting data in a visual way? This course let you know how by
	//         leveraging <b>information design</b> principles.
	//       </p>
	//     </div>
	//   ),
	// },
	// {
	//   name: "Scraping",
	//   link: "scraping",
	//   image: scraping_img,
	//   type: ["Python Coding", "Tech"],
	//   description: (
	//     <div>
	//       <p>
	//         Do you need a <b>massive</b> amount of data from a website to train
	//         your model? With web scraping, you can get data from web in an
	//         automated, fast and reliable way with a crawler/bot.
	//       </p>
	//     </div>
	//   ),
	// },
	// {
	//   name: "Data Exploration",
	//   link: "data_exploration",
	//   image: data_exploration,
	//   type: ["Python Coding", "Tech"],
	//   description: (
	//     <div>
	//       <p>
	//         Dirty data? Data manipulation and data cleaning with Python Pandas,
	//         explore and visualise it with Matplotlib and Seaborn.
	//       </p>
	//     </div>
	//   ),
	// },
	// {
	//   name: "Machine Learning",
	//   link: "machinelearning",
	//   image: ML_img,
	//   type: ["Python Coding", "Tech"],
	//   description: (
	//     <div>
	//       <p>
	//         This course will guide you through the amazing Machine Learning world:
	//         you will build classifiers, regressors and train / evaluate your own
	//         models with metrics.
	//       </p>
	//     </div>
	//   ),
	// },
	// {
	// 	name: "Deep Learning with Python 🐍",
	// 	link: "deeplearning",
	// 	image: ML_img,
	// 	type: ["Python 🐍 Coding", "Deep Learning"],
	// 	location: ["Fuerteventura 🇪🇸 - October 12th - 15th, 2023"],
	// 	// event_date: ["October 5th - 8th, 2023"],
	// 	deadline: ['Reservation deadline: 12 September 2023'],
	// 	description: (
	// 		<div>
	//   <p>
	//     At the end of the course, you will have gained a solid grasp on how a NN
	//     is working, is trained, how to make inferences and how to correctly tune a
	//     NN choosing its structure and complexity for your data.
	//   </p>
	//   <p>
	//     The course is <b>hands-on</b>: we will use Python as coding language with{" "}
	//     <b>PyTorch</b> as deep learning library.
	//   </p>
	// 		</div>
	// 	),
	// },
	// {
	// 	name: "Generative AI with Python 🐍",
	// 	link: "generative_ai_python",
	// 	image: deep_learning,
	// 	type: ["Python 🐍 Coding", "Generative AI"],
	// 	location: ["Fuerteventura 🇪🇸 - October 7th - 10th, 2023"],
	// 	// event_date: ["October 5th - 8th, 2023"],
	// 	deadline: ['Reservation deadline: 7 September 2023'],
	// 	description: (
	// 		<div>
	// 			<p>
	// 				At the end of the course, you will have gained a solid grasp on how Generative AI
	// 				works and how to create your own models. This is a technical course and we will
	// 				code with Python.
	// 			</p>
	// 		</div>
	// 	),
	// },
	// {
	//   name: "Data Viz",
	//   link: "deeplearning",
	//   image: data_viz,
	//   description: (
	//     <div>
	//       <p className="mb-2">
	//         In this <b>no-coding</b> course, the goal is to learn how to present
	//         complex data in an effective way. Which are the most effective colours
	//         to deliver a specific idea with an infographic? What is the story
	//         telling?
	//       </p>
	//       <p>
	//         You will learn how to present your data at their best to deliver solid
	//         and effective ideas based on data. There will be Python hands-on
	//         exercises and projects.
	//       </p>
	//     </div>
	//   ),
	// },
	// {
	//   name: "NLP",
	//   link: "nlp",
	//   image: NLP_img,
	//   type: ["Python Coding", "Tech"],
	//   description: (
	//     <div>
	//       <p>
	//         What is a language model? How is it possible to train a machine to
	//         understand the sentiment of a sentence? This and more in this course,
	//         where we will use Python and its powerful deep learning libraries.
	//       </p>
	//     </div>
	//   ),
	// },
];

const Events = ({ handleSelectionCourse }) => {
	return (
		<div id="courses_section">
			<div className="bg-slate-800 px-4 py-[7rem]">
				<div className="md:ml-[8rem] text-left">
					<p className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
						Choose your courses
					</p>
					<h1 className="text-4xl py-2 text-white mb-0 font-extrabold sm:text-4xl">
						Available courses
					</h1>
					{/* <h2 className="text-xl py-2 text-white mb-0"></h2> */}
				</div>
				<div className="flex">
					{/* <NumberedMenuWhite active={["1"]} /> */}
					<div className="flex flex-wrap py-10 md:mx-[5rem]">
						{events.map((event) => (
							<Event
								img={choose_random_img()}
								name={event.name}
								key={event.name}
								days={event.duration}
								deadline={event.deadline}
								when={event.when}
								event_type={event.type}
								level={event.level}
								event_date={event.event_date}
								mentors={event.mentors}
								where={event.where}
								location={event.location}
								link={event.link}
								description={event.description}
								image={event.image}
								handleSelectionCourse={handleSelectionCourse}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

const Event = ({
	name,
	description,
	img,
	where,
	when,
	days,
	level,
	deadline,
	event_date,
	location,
	link,
	event_type,
	image,
	handleSelectionCourse,
}) => {
	return (
		<div className="py-4 md:w-1/2 lg:w-1/3 w-full md:pr-6 flex">
			<div className="bg-white rounded-lg overflow-hidden flex flex-col justify-between">
				<div>
					<div>
						<img
							src={image}
							className="h-[10rem] w-full object-cover"
							alt=""
						></img>
					</div>
					<div className="mx-5">
						<h1 className="text-2xl text-left pt-5 mb-0 font-extrabold">
							{name}
						</h1>
						<div className="">
							{event_type &&
								event_type.map((sp, ind) => (
									<span
										key={ind}
										className="text-base inline-block my-2 bg-blue-200 text-blue-800 font-medium mr-2 px-3 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800"
									>
										{sp}
									</span>
								))}
						</div>
						<div className="">
							{location &&
								location.map((sp, ind) => (
									<span
										key={ind}
										className="text-base inline-block my-2 bg-green-200 text-green-800 font-medium mr-2 px-3 py-0.5 rounded dark:bg-green-200 dark:text-green-800"
									>
										{sp}
									</span>
								))}
						</div>
						<div className="">
							{deadline &&
								deadline.map((sp, ind) => (
									<span
										key={ind}
										className="text-base inline-block my-2 bg-red-200 text-red-800 font-medium mr-2 px-3 py-0.5 rounded dark:bg-red-200 dark:text-red-800"
									>
										{sp}
									</span>
								))}
						</div>
						<div className="">
							{event_date &&
								event_date.map((sp, ind) => (
									<span
										key={ind}
										className="text-base inline-block my-2 bg-green-200 text-green-800 font-medium mr-2 px-3 py-0.5 rounded dark:bg-green-200 dark:text-green-800"
									>
										{sp}
									</span>
								))}
						</div>
						<div className="text-base text-left">{description}</div>
					</div>
				</div>
				<div className="mx-5">
					<div className="mt-5 sm:mt-8 mb-8 flex justify-between">
						<div className="rounded-md shadow grow">
							<a
								href={link}
								className="w-full px-4 flex items-center justify-center py-3 border border-transparent text-base font-medium rounded-md text-white bg-sky-700 hover:bg-sky-600 md:py-4 md:text-lg md:px-10"
							>
								More info
							</a>
						</div>
						{/* <div className="text-center flex">
              <label htmlFor={name} className="m-2 self-center">
                Choose this course
              </label>
              <input
                type="checkbox"
                id={name}
                onClick={(event) => handleSelectionCourse(name, event)}
                className="self-center bg-white border-green-300 text-green-500 focus:ring-green-200 shadow checked:shadow-xl w-8 h-8 rounded"
              />
            </div> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Events;
