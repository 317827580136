import CallToAction2 from "../components/CallToAction2";
import TextBlock from "../components/TextBlock";
import Prices from "../components/Prices";
import Mentor from "../components/Mentor";
import Venue from "../components/Venue";
import Footer from "../components/Footer";
import HeaderCourse from "../components/HeaderCourse";
import ImageGallery from "../components/ImageGallery";
import { PhoneIcon, SupportIcon, SaveAsIcon } from "@heroicons/react/outline";
import syllabus from "../syllabus/NLP-program-CodeNet.pdf";

const description = (
	<div>
    <p>
      At the end of the course, you will have gained a solid grasp on how a NN
      is working, is trained, how to make inferences and how to correctly tune a
      NN choosing its structure and complexity for your data.
    </p>
	</div>
);

const subtitle = (
	<div>
    <p>Looking to get a grip on Deep Learning and how to create models?</p>
    <p>
      We will explore Deep Learning and why it is different from Machine
      Learning.
    </p>
	</div>
);

// const supportLinks = [
// 	{
// 		name: "Max 20 students",
// 		description: (
// 			<p>
// 				Anyone can attend this course, but to fully enjoy the hands-on sessions
// 				it is advisable a basic Python knowldge. But anyone curious about ML and
// 				DL are can join even if he/she can't code.
// 			</p>
// 		),
// 		icon: PhoneIcon,
// 	},
// 	{
// 		name: "Schedule and organization",
// 		description: (
// 			<div>
// 				<p>
// 					We will have lessons only in the morning from 9:30, with some breaks
// 					in between.
// 				</p>
// 				<p className="py-3">
// 					For the ones willing to learn to surf, we will go in the afternoon,
// 					ready to catch some waves! 🌊🏄‍♂️
// 				</p>
// 			</div>
// 		),
// 		icon: SupportIcon,
// 		link: (
// 			<div>
// 				<p className="py-2">First day of lesson: 1/3/2023 - (Wednesday)</p>
// 				<p className="py-2">Last day of lesson: 4/03/2023 - (Saturday)</p>
// 			</div>
// 		),
// 	},
// 	{
// 		name: "Where",
// 		href: "https://www.google.com/maps/place/Surfing+Colors/@28.7419265,-13.8711471,16.82z/data=!4m8!3m7!1s0x0:0xe8e4eed62badc2!5m2!4m1!1i2!8m2!3d28.7422639!4d-13.8700805",
// 		description: (
// 			<p>
// 				We will be in Fuerteventura - Canary Islands (Spain). The coworking
// 				space is inside{" "}
// 				<a
// 					className="text-blue-500 underline"
// 					rel="noreferrer"
// 					href="https://www.surfingcolors.com/"
// 					target="_blank"
// 				>
// 					Surfing Colors Hotel{" "}
// 				</a>
// 				and the arrival airport is <span className="text-indigo-600">FUE</span>
// 			</p>
// 		),
// 		icon: PhoneIcon,
// 		link: "Google Maps",
// 	},
// ];

const description_course = (
	<div>
    <p className="text-justify mb-5">
      <span className="text-xl m-2 bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
        This is a <b>TECHNICAL</b> course (Python coding 🐍)
      </span>
    </p>
    <p>Looking to get a grip on Deep Learning and how to create models?</p>
    <p>
      We will explore Deep Learning and why it is different from Machine
      Learning.
    </p>
    <p>
      At the end of the course, you will have gained a solid grasp on how a NN
      is working, is trained, how to make inferences and how to correctly tune a
      NN choosing its structure and complexity for your data.
    </p>
    <p>
      The course is <b>hands-on</b>: we will use Python as coding language with{" "}
      <b>PyTorch</b> as deep learning library.
    </p>

	</div>
);

const topics = [
  {
    title: "What is Deep Learning",
  },
  {
    title: "What is a NN and how it works in details",
  },
  {
    title: "How it is trained, what is a loss function and what is an epoch",
  },
  {
    title: "How to choose the correct model complexity fitting your data",
  },
  {
    title: "How to avoid 'traps' and advices from experts",
  },
];

const info_where_when = [
	{
		name: "Admission",
		href: syllabus,
		description: `The course is for anyone willing to understand how deep learning works.
		You should have a basic understanding of Python. Even if it is technical, even somebody
		who can't code can join but he will understand only the theoretical concepts.`,
		icon: SaveAsIcon,
		link: null,
	},
	{
		name: "When & Deadline",
		href: "mailto:info@codingwaves.org",
		description: <div><p><b>Course dates:</b> 12th-15th October 2023 (first and last day of lesson), 5 hrs per day. </p>
		<p><b>Deadline for pre-registration:</b> 12 September 2023</p></div>,
		icon: SupportIcon,
		link: "Contact us (info[at]codingwaves.org)",
	},
	{
		name: "Where",
		href: "https://www.google.com/maps/place/Surfing+Colors/@28.7422639,-13.8700805,15z/data=!4m8!3m7!1s0x0:0xe8e4eed62badc2!5m2!4m1!1i2!8m2!3d28.7422639!4d-13.8700805",
		description:
			"We will be in the sunny Fuerteventura (Canary Island - Spain) in the Coworking space of Surfing Colors Hotel.",
		icon: PhoneIcon,
		link: "Google Maps (Surfing Colors)",
		link2: "Surfing Colors Hotel (website)",
	},
];

const info_prices = {
	price: 450,
	what_to_bring: ["Laptop and charger", "Pen and papers for notes"],
	included: ["Lessons: 16 hours of lessons with skilled trainer", "Coworking space"],
	not_included: ["Flight", "Accomodation (but you will have a special discount code)", 
  "Commuting from/to airport", "Surf Lessons (but you will have a special discount"],
};

export default function AiForExecutives() {
	return (
		<>
			{/* <Banner /> */}
			<HeaderCourse
				title="Deep Learning"
				subtitle={subtitle}
				bg_image="bg-aiexec"
			/>
			<CallToAction2
				subtitle="CodingWaves"
				title="Deep Learning Course with Python 🐍"
				description={description}
			/>
			<TextBlock
				short_uptitle={"Unleash and control AI power"}
				title={"Understand how deep learning works"}
				description={description_course}
				topics={topics}
			/>
			<Mentor mentor_names={["Carlo Ceriotti", "Cristiano De Nobili"]} />
			<Venue info_where_when={info_where_when}/>
			<ImageGallery />
			<Prices {...info_prices} />
			<Footer />
		</>
	);
}
