import CallToAction2 from "../components/CallToAction2";
import TextBlock from "../components/TextBlock";
import Prices from "../components/Prices";
import Mentor from "../components/Mentor";
import Venue from "../components/Venue";
import Footer from "../components/Footer";
import HeaderCourse from "../components/HeaderCourse";
import ImageGallery from "../components/ImageGallery";
import { PhoneIcon, SupportIcon, SaveAsIcon } from "@heroicons/react/outline";
import syllabus from "../syllabus/NLP-program-CodeNet.pdf";

import fuerte1 from "../images/coliving2.jpg";
import fuerte2 from "../images/coliving3.jpg";
import fuerte3 from "../images/coliving4.jpg";

import coworking_milan1 from "../images/coworking_milan1.jpeg"
import coworking_milan2 from "../images/coworking_milan2.jpeg";
import coworking_milan3 from "../images/coworking_milan3.jpeg";


const description = (
	<div>
		<p class="my-2">
			This is a <b>NO TECHNICAL</b> course
		</p>
		<p class="my-2">
			Looking to get a grip on AI and how to use it to drive your business
			forward?
		</p>
		<p>This course is for designed for CEO and Executives.</p>
	</div>
);

const subtitle = (
	<p>
		Looking to get a grip on AI and how to use it to drive your business
		forward?
		<br />
		This course is for CEO and Executives
	</p>
);

// const supportLinks = [
// 	{
// 		name: "Max 20 students",
// 		description: (
// 			<p>
// 				Anyone can attend this course, but to fully enjoy the hands-on sessions
// 				it is advisable a basic Python knowldge. But anyone curious about ML and
// 				DL are can join even if he/she can't code.
// 			</p>
// 		),
// 		icon: PhoneIcon,
// 	},
// 	{
// 		name: "Schedule and organization",
// 		description: (
// 			<div>
// 				<p>
// 					We will have lessons only in the morning from 9:30, with some breaks
// 					in between.
// 				</p>
// 				<p className="py-3">
// 					For the ones willing to learn to surf, we will go in the afternoon,
// 					ready to catch some waves! 🌊🏄‍♂️
// 				</p>
// 			</div>
// 		),
// 		icon: SupportIcon,
// 		link: (
// 			<div>
// 				<p className="py-2">First day of lesson: 1/3/2023 - (Wednesday)</p>
// 				<p className="py-2">Last day of lesson: 4/03/2023 - (Saturday)</p>
// 			</div>
// 		),
// 	},
// 	{
// 		name: "Where",
// 		href: "https://www.google.com/maps/place/Surfing+Colors/@28.7419265,-13.8711471,16.82z/data=!4m8!3m7!1s0x0:0xe8e4eed62badc2!5m2!4m1!1i2!8m2!3d28.7422639!4d-13.8700805",
// 		description: (
// 			<p>
// 				We will be in Fuerteventura - Canary Islands (Spain). The coworking
// 				space is inside{" "}
// 				<a
// 					className="text-blue-500 underline"
// 					rel="noreferrer"
// 					href="https://www.surfingcolors.com/"
// 					target="_blank"
// 				>
// 					Surfing Colors Hotel{" "}
// 				</a>
// 				and the arrival airport is <span className="text-indigo-600">FUE</span>
// 			</p>
// 		),
// 		icon: PhoneIcon,
// 		link: "Google Maps",
// 	},
// ];

const description_course = (
	<div>
		<p className="text-justify mb-5">
			<span className="text-xl m-2 bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
				This is a <b>NO TECHNICAL</b> course (no coding)
			</span>
		</p>
		<p>
			Looking to get a grip on AI and how to use it to drive your business
			forward?
		</p>
		<p>
			We'll cover the basics of Machine Learning and Deep Learning, and help you
			understand how to start an AI project from scratch.
		</p>
		<p>
			You'll learn about the minimum professional profiles you need for your
			team, how to manage and store data, and the pros and cons of buying versus
			building or outsourcing your own AI solutions. Plus, we'll delve into
			emerging technologies and how to stay ahead of the curve.
		</p>
		<p>
			By the end of this course, you'll be equipped with the knowledge and
			skills you need to leverage AI for your company's success. The number of
			areas in which AI can be applied has increased in recent years. It is
			expected to grow even faster in the near future. Anyone who thinks of
			neglecting this AI opportunity will be missing a great opportunity for
			growth.
		</p>
	</div>
);

const topics = [
	{
		title:
			"The big picture: classification of AI technologies and which to use",
	},
	{
		title: "What AI can do today: real use business cases and applications",
	},
	{
		title: "How to manage and organise an AI project",
	},
	{
		title: "New team: which professional AI profiles to search?",
	},
	{
		title: "When is it better to outsource an AI solution?",
	},
	{
		title: "Future of AI: trends and directions to be always cutting-edge",
	},
];

const info_where_when = [
	{
		name: "Admission",
		href: syllabus,
		description: `The course is for anyone willing to understand how AI is impacting our 
    society and how to organise and design properly an AI project.`,
		icon: SaveAsIcon,
		link: null,
	},
	{
		name: "When & Deadline",
		href: "mailto:info@codingwaves.org",
		description: <div><p><b>Course dates:</b> 14th October 2023, 1 day (8 hours). </p>
		<p><b>Deadline for pre-registration:</b> 14 September 2023</p></div>,
		icon: SupportIcon,
		link: "Contact us (info[at]codingwaves.org)",
	},
	{
		name: "Where",
		href: "",
		description:
			"We will be in a coworking in Milan in our network (to be defined)",
		icon: PhoneIcon,
		link: "",
		link2: "",
	},
];

const info_prices = {
	price: 210,
	what_to_bring: ["Laptop and charger", "Pen and papers for notes"],
	included: ["Lessons: 1 full day (8 hours) hours of lessons with skilled trainer", "Coworking space", "Lunch"],
	not_included: [],
};

const images_coworking = {'fuerteventura': [fuerte1, fuerte2, fuerte3], 
													'milan': [coworking_milan1, 
														coworking_milan2, coworking_milan3]}

export default function AiForExecutives() {
	return (
		<>
			{/* <Banner /> */}
			<HeaderCourse
				title="AI for executives"
				subtitle={subtitle}
				bg_image="bg-aiexec"
			/>
			<CallToAction2
				subtitle="CodeNet"
				title="AI for Executives, managers and CEO"
				description={description}
			/>
			<TextBlock
				short_uptitle={"Unleash and control AI power"}
				title={"AI for executives, managers and CEO"}
				description={description_course}
				topics={topics}
			/>
			<Mentor mentor_names={["Carlo Ceriotti", "Cristiano De Nobili"]} />
			<Venue info_where_when={info_where_when}/>
			<ImageGallery images_coworking={images_coworking['milan']}/>
			<Prices {...info_prices} />
			<Footer />
		</>
	);
}
